import request from '@/utils/request'

export function add(data) {
  return request({
    url: "api/subject",
    method: "post",
    data
  })
}

export function edit(data) {
  return request({
    url: "api/subject",
    method: "put",
    data
  })
}

export function del(id) {
  return request({
    url: "api/subject/" + id,
    method: "delete"
  })
}

