<template>
  <div class="app-container">
    <div class="head-container">
      <el-input v-model="query.name" clearable placeholder="根据名称搜索" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery" />
      <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" @change="toQuery" />
      <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <el-button class="filter-item" size="mini" type="primary" icon="el-icon-plus" @click="add">新增</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>
    <eForm ref="form" :is-add="isAdd" />
    <el-table v-loading="loading" row-key="id" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="name" label="活动" min-width="200" />
      <el-table-column label="活动类型" min-width="200">
        <template slot-scope="scope">
          <el-tag type="info">{{status[scope.row.subjectType].label}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="money" label="收费金额" align="right" width="120" :formatter="$price" />
      <el-table-column prop="begDate" label="开始日期" width="140" :formatter="v=>{return v.begDate?new Date(v.begDate).format('yyyy/MM/dd hh:mm:ss'):''}" />
      <el-table-column prop="endDate" label="结束日期" width="140" :formatter="v=>{return v.endDate?new Date(v.endDate).format('yyyy/MM/dd hh:mm:ss'):''}" />
      <el-table-column prop="info" label="活动说明" show-overflow-tooltip min-width="240" />
      <el-table-column width="100" align="center" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <el-button size="mini" type="text" @click="edit(scope.row)">编辑</el-button>
          <el-popover :ref="scope.row.id" placement="top" width="180">
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
            </div>
            <el-button slot="reference" class="danger" type="text" size="mini">删除</el-button>
          </el-popover>
        </div>
      </el-table-column>
    </el-table>
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
  </div>
</template>

<script>
import { del } from "@/api/subject";
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import eForm from "./form";

export default {
  components: { eForm },
  mixins: [initData],
  data() {
    return {
      delLoading: false,
      loading: false,
      query: {
        name: null,
        dateRange: null,
      },
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      status: {
        purpose: {
          label: "窜货保证金",
        },
        signup: {
          label: "报名费",
        },
        ensure: {
          label: "保证金",
        },
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.init();
    });
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/subject";
      let query = JSON.parse(JSON.stringify(this.query));
      if (query.dateRange && query.dateRange.length === 2) {
        query.begDateMin = query.dateRange[0];
        query.begDateMax = query.dateRange[1];
      }
      delete query.dateRange;
      query.sort = "createAt,desc";
      this.params = query;
      return true;
    },

    add() {
      this.isAdd = true;
      const _this = this.$refs.form;
      _this.restForm();
    },
    edit(data) {
      this.isAdd = false;
      const _this = this.$refs.form;
      _this.restForm(JSON.parse(JSON.stringify(data)));
    },
    subDelete(id) {
      this.delLoading = true;
      del(id)
        .then((res) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500,
          });
        })
        .catch((err) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          console.log(err.response.data.message);
        });
    },
  },
};
</script>