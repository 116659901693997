<template>
  <div>
    <el-dialog :append-to-body="true" :visible.sync="dialog" :title="isAdd ? '新增活动':'修改活动'" :close-on-click-modal="false" @closed="cancel" width="720px">
      <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="86px" label-suffix=":" v-if="form" :disabled="disabled">
        <el-form-item label="活动名称" prop="name">
          <el-input v-model="form.name" :maxlength="40" />
        </el-form-item>
        <div class="h sb">
          <el-form-item label="活动类型" prop="subjectType">
            <el-select v-model="form.subjectType" placeholder="请选择活动类型" style="width: 240px">
              <el-option v-for="item in subjectTypeOptions" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item prop="money" label="收费金额">
            <price-input v-model="form.money" :min="0" :max="999999999" :step-ratio="10000" style="width: 240px" />
          </el-form-item>
        </div>
        <div class="h sb">
          <el-form-item label="开始日期" prop="begDate">
            <el-date-picker type="datetime" size="mini" v-model="form.begDate" value-format="timestamp" placeholder="开始日期" style="width: 240px" default-time="00:00:00" />
          </el-form-item>
          <el-form-item label="结束日期" prop="endDate">
            <el-date-picker type="datetime" size="mini" v-model="form.endDate" value-format="timestamp" placeholder="结束日期" style="width: 240px" default-time="23:59:59" />
          </el-form-item>
        </div>
        <el-form-item label="活动说明" prop="info">
          <el-input type="textarea" v-model="form.info" :rows="3" resize="none" maxlength="250" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="text" @click="dialog = false">取消</el-button>
        <el-button :loading="loading" type="primary" @click="doSave">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { add, edit } from "@/api/subject";
export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true,
    },
  },
  mounted() {},
  data() {
    return {
      loading: false,
      searching: false,
      dialog: false,
      disabled: false,
      form: null,
      subjectTypeOptions: [
        {
          value: "purpose",
          label: "窜货保证金",
        },
        {
          value: "signup",
          label: "报名费",
        },
        {
          value: "ensure",
          label: "保证金",
        },
      ],
      rules: {
        name: [
          { required: "true", message: "请输入活动名称", trigger: "blur" },
        ],
        subjectType: [
          { required: "true", message: "请选择活动类型", trigger: "change" },
        ],
        payMoney: [{ required: true, message: "请填写收费金额" }],
        begDate: [{ required: true, message: "请选择开始日期" }],
        endDate: [{ required: true, message: "请选择结束日期" }],
      },
    };
  },
  methods: {
    cancel() {
      this.form = null;
    },
    doSave() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loading = true;
          if (this.isAdd) {
            this.doAdd();
          } else this.doEdit();
        }
      });
    },
    doAdd() {
      // let form = JSON.parse(JSON.stringify(this.form));

      add(this.form)
        .then((res) => {
          this.dialog = false;
          this.$notify({
            title: "保存成功",
            type: "success",
            duration: 2500,
          });
          this.loading = false;
          this.$parent.init();
        })
        .catch((err) => {
          this.loading = false;
          console.log(err.response.data.message);
        });
    },
    doEdit() {
      edit(this.form)
        .then((res) => {
          this.dialog = false;
          this.$notify({
            title: "修改成功",
            type: "success",
            duration: 2500,
          });
          this.loading = false;
          this.$parent.init();
        })
        .catch((err) => {
          this.loading = false;
          console.log(err.response.data.message);
        });
    },
    restForm(form) {
      this.dialog = true;
      this.disabled = false;
      this.form = form || {
        name: null,
        money: null,
        subjectType: null,
        begDate: null,
        endDate: null,
        info: "",
      };
    },
  },
};
</script>

